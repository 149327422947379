import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { graphql, Link, withPrefix } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Layout from "../components/layout";
import MainContent from "../components/maincontent";
import PageBanner from "../components/banner/banner";
import React from "react";
import Seo from "../components/seo";

class TagRoute extends React.Component {

  render() {
    const posts = this.props.data.allMarkdownRemark.edges;
    const postLinks = posts.map((post) => (
      // This duplicates the same template as /src/page/blog/index - this can probably be repeated better.
      <div key={`key-${post.node.frontmatter.title}`}>
        <header className="blog-list--header">
          <Link className="blog-list--heading" to={post.node.frontmatter.path}>
            <div className="flex flex--standard blog-preview">
              <img
                src={`${withPrefix(post.node.frontmatter.image)}`}
                alt="post-image"
              />
            </div>
            <h2
              className="blog-list--header"
            >
              <span>{post.node.frontmatter.title}</span>
            </h2>
          </Link>
        </header>
        <div className="blog-date">
          {post.node.frontmatter.date}
        </div>
        <span>
          {post.node.frontmatter.description}
          &nbsp;
          <Link
            className="link link--blue"
            to={post.node.frontmatter.path}
          >
            Read More...
          </Link>
        </span>
        <hr className="blog-hr" />
      </div>
    ));

    const tag = this.props.pageContext.tag;
    const totalCount = this.props.data.allMarkdownRemark.totalCount;
    const tagHeader = `${totalCount} post${
      totalCount === 1 ? "" : "s"
    } tagged with “${tag}”`;

    return (
      <Layout>
        <Seo
          title={`Topic | ${tag}`}
          description="Categories"
          lang="en"
          meta={[
            {
              content: "God, Jesus, Clear, Gospel, Truth, Heaven, Salvation, Saved, Grace, Free, How, Mid-Acts, Romans",
              name: "keywords"
            }
          ]}
        />

        <PageBanner
          hasImage={false}
          hasOverlay={false}
        />

        <MainContent
          hasArticle={true}
        >
          <div className='article-header'>
            <Link className="button button--grey" to="/blog" style={{ marginRight: "0.5rem" }}><FontAwesomeIcon icon={faChevronLeft} /> Blog </Link>
            <h1>
               Topic: {tag}
            </h1>
          </div>

          <div className="card">
            <div className="grid article-width">
              <h2 className="">{tagHeader}</h2>
              <section>
                {postLinks}
              </section>

              <p>
                <Link className="link link--blue" to="/blog/tags/">Browse All Topics</Link>
              </p>
            </div>
          </div>


        </MainContent>
      </Layout>
    );
  }

}

export default TagRoute;

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            image
            description
            tags
          }
        }
      }
    }
  }
`;
